import { AvailabilityAndPricing, ModelDoc } from '../../../types/backendModels/availability'
import { Booking } from '../../../types/backendModels/booking'

import { Content, AddedPersonalInfoValue, PurchaseValue, ViewContentValue } from './types'

function mkContent(car: AvailabilityAndPricing): Content {
    return {
        id: car.availability.id,
        quantity: 1,
        item_name: ModelDoc.fullName(car.availability.modelDoc),
        item_category: car.parameters.category, //search size
        affiliation: `${car.availability.rentalId}`, // rental id + rental name
    }
}

function mkContentFromBooking(booking: Booking): Content {
    return {
        id: booking.availabilityId,
        quantity: 1,
        item_name: booking.model.name,
        item_category: booking.model.size, //search size
        affiliation: `${booking.rentalId}-${booking.rentalName}`, // rental id + rental name
        price: booking.price.commission,
        currency: 'EUR',
    }
}

export function mkViewAvailabilitiesEventValue(
    searchAvailabilities: AvailabilityAndPricing[]
): ViewContentValue {
    const contents = searchAvailabilities.map(mkContent)
    return {
        contents: contents,
    }
}
export function mkViewSingleAvailabilityEventValue(
    searchAvailability: AvailabilityAndPricing
): ViewContentValue {
    const content = mkContent(searchAvailability)
    return {
        contents: [content],
    }
}

export function mkAddedPersonalInfoValue(booking: Booking): AddedPersonalInfoValue {
    return {
        contents: [mkContentFromBooking(booking)],
        value: booking.price.commission,
        currency: 'EUR',
    }
}

export function mkPurchaseValue(booking: Booking): PurchaseValue {
    return {
        ...mkAddedPersonalInfoValue(booking),
        content_type: 'product',
    }
}
