import React from 'react'
import Link from 'next/link'
import Image from 'next/image'
import { DestinationWidgetProps } from '../../../types'
import { useTranslation } from 'next-i18next'
import dayjs from 'dayjs'
import { ErrorBoundary } from 'next/dist/client/components/error-boundary'
import ErrorComponent from '../../ErrorComponent'
import { FaXTwitter, FaFacebook, FaLinkedin } from 'react-icons/fa6'
interface Props {
  destinationsWidget?: DestinationWidgetProps[]
}

const Footer: React.FC<Props> = ({ destinationsWidget }) => {
    const { t, ready } = useTranslation()
    return (
        <ErrorBoundary errorComponent={({ error }) => ErrorComponent('Footer', error)}>
            <>
                {ready && (
                    <footer className="footer-area bg-fffcf4">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-4 col-md-6">
                                    <div className="single-footer-widget">
                                        <div className="logo">
                                            <Link href="/">
                                                <Image
                                                    src="/images/assets/logo-sb.png"
                                                    alt="Simplybook Logo"
                                                    width={50}
                                                    height={50}
                                                />
                                                <span className="nav-title">Simplybook</span>
                                            </Link>
                                        </div>
                                        <ul className="contact-info">
                                            <li>{t('footer_address_1')}</li>
                                            <li>{t('footer_address_2')}</li>
                                            <br />
                                            <li>
                                                <a href="tel:+302112348200">+302112348200</a>
                                            </li>
                                            <li>
                                                <a href="mailto:rentals@simplybook.com">rentals@simplybook.com</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <div className="single-footer-widget pl-5">
                                        <h2 className="black-font">{t('Popular Destinations')}</h2>
                                        <ul className="list">
                                            <li>
                                                <a href="https://www.simplybook.com/blog/">{t('Our blog')}</a>
                                            </li>
                                            {destinationsWidget?.map((place) => (
                                                <li key={place.id}>
                                                    <Link
                                                        href="/rent/car/[place]"
                                                        as={`/rent/car/${place?.title?.toLocaleLowerCase()}`}
                                                    >
                                                        {place?.title}
                                                    </Link>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <div className="single-footer-widget pl-5">
                                        <h2 className="black-font">{t('For Businesses')}</h2>
                                        <ul className="list">
                                            <li>
                                                <a href="https://admin.simplybook.com/#/login">{t('Login')}</a>
                                            </li>
                                            <li>
                                                <a href="https://admin.simplybook.com/#/register">{t('Sign in')}</a>
                                            </li>
                                            <li>
                                                <Link href="/become-a-partner">{t('Become a partner')}</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-6">
                                    <div className="single-footer-widget pl-5">
                                        <h2 className="black-font">{t('Support')}</h2>
                                        <ul className="list">
                                            <li>
                                                <Link href="/faq">FAQ</Link>
                                            </li>
                                            <li>
                                                <Link href="/about">{t('About us')}</Link>
                                            </li>
                                            <li>
                                                <Link href="/privacy-policy">{t('Privacy Notice')}</Link>
                                            </li>
                                            <li>
                                                <Link href="/terms-of-use">{t('Terms & Conditions')}</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="copyright-area">
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-lg-7 col-md-7">
                                        <p>
                                            <i className="far fa-copyright"></i> 2022 - {dayjs().year()}{' '}
                                            <Link href="/">Simplybook</Link>. All Rights Reserved
                                        </p>
                                    </div>
                                    <div className="col-lg-5 col-md-5">
                                        <ul>
                                            <li>
                                                <a
                                                    title="facebook"
                                                    rel="noopener noreferrer"
                                                    target="_blank"
                                                    href="https://www.facebook.com/simplybookcom/"
                                                >
                                                    <FaFacebook />
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    title="twitter"
                                                    rel="noopener noreferrer"
                                                    target="_blank"
                                                    href="https://twitter.com/simplybookcom"
                                                >
                                                    <FaXTwitter />
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    title="linked in"
                                                    rel="noopener noreferrer"
                                                    target="_blank"
                                                    href="https://www.linkedin.com/company/28881502"
                                                >
                                                    <FaLinkedin />
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="map2">
                            <Image src="/images/assets/map2.png" alt="Map" fill />
                        </div>
                    </footer>
                )}
            </>
        </ErrorBoundary>
    )
}

export default Footer
