import { atom, getDefaultStore } from 'jotai'
import { GoogleMapBounds } from '../types'
import { Fuel } from '../types/backendModels/availability'
import { atomWithStorage } from 'jotai/utils'

const greeceBounds: GoogleMapBounds = {
    southwest: {
        lat: 34.802075,
        lng: 19.371601,
    },
    northeast: {
        lat: 41.748877,
        lng: 28.24002,
    },
}

interface SearchBoxParameters {
  hasReturnLocation: boolean
  pickupDateTime: Date
  returnDateTime: Date
  pickupLocation: {
    description: string
    googlePlaceId: string
    formatted_address: string
    lat: number
    lng: number
  } | null
  returnLocation: {
    description: string
    googlePlaceId: string
    formatted_address: string
    lat: number
    lng: number
  } | null
  vType: string
  vCategory: string
  transmissionType: string
  showAdvancedOptions: boolean
  seats?: number
  fuel: Fuel
  creditCardNeeded: boolean
}

const initialSearchBoxParameters: SearchBoxParameters = {
    hasReturnLocation: false,
    pickupLocation: null,
    returnLocation: null,
    pickupDateTime: null,
    returnDateTime: null,
    vType: null,
    vCategory: 'Car',
    transmissionType: '',
    showAdvancedOptions: false,
    seats: 0,
    fuel: null,
    creditCardNeeded: null,
}

const createStorageWithTTL = (ttlMinutes: number) => {
    if (typeof window === 'undefined') {
        return {
            getItem: () => initialSearchBoxParameters,
            setItem: () => null,
            removeItem: () => null,
        }
    }
    
    return {
        getItem: (key: string) => {
            try {
                const item = localStorage.getItem(key)
                if (!item) return initialSearchBoxParameters

                const { value, timestamp } = JSON.parse(item)
                const now = new Date().getTime()

                if (now - timestamp > ttlMinutes * 60 * 1000) {
                    localStorage.removeItem(key)
                    return initialSearchBoxParameters
                }

                return {
                    ...value,
                    pickupDateTime: value.pickupDateTime ? new Date(value.pickupDateTime) : null,
                    returnDateTime: value.returnDateTime ? new Date(value.returnDateTime) : null
                }
            } catch (e) {
                console.error('Error reading from storage:', e)
                return initialSearchBoxParameters
            }
        },
        setItem: (key: string, value: unknown) => {
            try {
                const item = {
                    value,
                    timestamp: new Date().getTime(),
                }
                localStorage.setItem(key, JSON.stringify(item))
            } catch (e) {
                console.error('Error writing to storage:', e)
            }
        },
        removeItem: (key: string) => {
            try {
                localStorage.removeItem(key)
            } catch (e) {
                console.error('Error removing from storage:', e)
            }
        },
    }
}

export const searchBoxParametersAtom = atomWithStorage<SearchBoxParameters>(
    'searchBoxParameters', 
    initialSearchBoxParameters, 
    createStorageWithTTL(120),
    { getOnInit: true }
)

const store = getDefaultStore()

export function setSearchboxParameters(searchBoxParameters: SearchBoxParameters) {
    store.set(searchBoxParametersAtom, searchBoxParameters)
}

const boundsAtom = atom<GoogleMapBounds>(greeceBounds)

const widgetModeAtom = atom<boolean>(false)

export function setWidgetMode() {
    store.set(widgetModeAtom, true)
}

export function setNormalMode() {
    store.set(widgetModeAtom, false)
}

export function getWidgetMode() {
    return store.get(widgetModeAtom)
}

export function getBounds() {
    return store.get(boundsAtom)
}

export function setBounds(bounds: GoogleMapBounds) {
    store.set(boundsAtom, bounds)
}
